import './styles/styles.min.css'

const logo = require('./assets/images/fancy-logo.png');

function App() {
    function handleClick() {
        window.location.href = "https://growerz.thc-labz.xyz"
    }

    return (
        <div className="construction">
            <img height={250} src={logo} alt="THC Labz Logo" />
            <h2>Maintenance Mode</h2>
            <h3>
                This service is currently in maintenance mode<br />
                Please try again later
            </h3>
        </div>
    );
}

export default App;
